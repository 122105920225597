import { auth, User } from "firebase"
import firebase from "gatsby-plugin-firebase"
import { useEffect, useReducer, useState } from "react"

interface State {
  user?: User
  claim?: any
  loading: boolean
  error?: any
}

type Action = { type: "auth_state_changed"; payload: any } | { type: "error"; payload: any }

const authReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "auth_state_changed":
      return {
        ...state,
        claim: action.payload.claim,
        user: action.payload.user,
        loading: false,
      };
    case "error":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  }
}

/**
 * ブラウザ判定
 */
export const isBrowser = () => typeof window !== "undefined"

/**
 * Firebaseのログイン情報監視
 */
export const useFirebaseAuth = (): [User | undefined, any, boolean, auth.Error | undefined] => {
  const [auth, setAuth] = useState<undefined | auth.Auth>(undefined)

  const [state, dispatch] = useReducer(authReducer,
    {
      user: undefined,
      claim: undefined,
      loading: true,
      error: undefined,
    }
  )
  useEffect(() => {
    setAuth(firebase.auth())
  }, [firebase])

  useEffect(() => {
    if (auth === undefined) return

    const unsubscribe = auth.onAuthStateChanged(
      (user) => {
        if (user) {
          user?.getIdTokenResult(true).then(result => {
            dispatch({
              type: "auth_state_changed",
              payload: {
                user: user,
                claim: result.claims
              }
            })
          }).catch(error => {
            dispatch({type: "error", payload: error})
          })
        } else {
          dispatch({
            type: "auth_state_changed",
            payload: {
              user: undefined,
              claim: undefined
            }
          })
        }
      },
      (error: auth.Error) => {
        dispatch({type: "error", payload: error})
      }
    )

    return (): void => {
      unsubscribe()
    }
  }, [auth])
  return [state.user, state.claim, state.loading, state.error]
}

export const getUser = () => {
  return firebase.auth().currentUser
}

export const logout = () => {
  return firebase.auth().signOut()
}
