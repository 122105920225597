import { makeStyles } from '@material-ui/core/styles'
const drawerWidth = 256
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  enableRow: {
    backgroundColor: theme.palette.background.paper
  },
  disableRow: {
    backgroundColor: theme.palette.action.hover
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paperContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3)
  },
  wrapMargin: {
    margin: theme.spacing(2),
  },
  verticalMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  horizontalMargin: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  templateDownloadPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  tableCellStyle: {
    wordWrap: "break-word",
    minWidth: 150,
    maxWidth: 500
  }
}));

