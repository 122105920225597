import React, { FunctionComponent, useCallback } from "react"
import { logout, useFirebaseAuth } from "../../utils/auth"
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import { useStyles } from "../../styles/useStyle"

export const Header: FunctionComponent = () => {
  const classes = useStyles()
  const [ user ] = useFirebaseAuth()

  const renderLogoutButton = useCallback(() => {
    let retVal = null
    if (user) {
      retVal = <Button variant={"contained"} color={"primary"} disableElevation onClick={logout}>Logout</Button>
    }

    return retVal
  }, [ user ])


  return (
    <AppBar position={"absolute"} className={classes.appBar}>
      <Toolbar>
        <Typography component={"h1"} variant={"h6"} noWrap style={{ flexGrow: 1 }}>
          <Link to={"/"} style={{ color: "white", textDecoration: "none"}}>
            CSV管理機能
          </Link>
        </Typography>
        {renderLogoutButton()}
      </Toolbar>
    </AppBar>
  )
}
