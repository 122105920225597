import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Header } from "../Header"
import { Container, CssBaseline, MuiThemeProvider, Typography } from "@material-ui/core"
import { useStyles } from "../../styles/useStyle"
import { ADMIN_NETWORK, getGlobalIp } from "../../utils/getGlobalIp"
import { theme } from "../../styles/theme"


export const Layout: FunctionComponent = ({ children }) => {
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ isAdminNetwork, setAdminNetwork ] = useState<boolean>(false)
  const classes = useStyles()
  let retVal = null

  useEffect(() => {
    // getGlobalIp().then(myIp => {
    //   setAdminNetwork(ADMIN_NETWORK.includes(myIp.ip))
    setAdminNetwork(true)
    setLoading(false)
    // })
  }, [])

  let renderLoading = (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth={"lg"} className={classes.container}>
          <div>
            <Typography>Loading...</Typography>
          </div>
        </Container>
      </main>
    </div>
  )

  let renderForbidden = (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth={"lg"} className={classes.container}>
          <div>
            <Typography>アクセスが許可されていません</Typography>
          </div>
        </Container>
      </main>
    </div>
  )

  let renderSuccess = (
    <div className={classes.root}>
      <Helmet title="CSV管理機能" />
      <CssBaseline />
      <Header />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={"lg"} className={classes.container}>
          <div>
            {children}
          </div>
        </Container>
      </main>
    </div>
  )

  if (loading) {
    retVal = renderLoading
  } else {
    if (isAdminNetwork) {
      retVal = renderSuccess
    } else
      retVal = renderForbidden
  }

  return (
    <MuiThemeProvider theme={theme}>
      {retVal}
    </MuiThemeProvider>
  )
}
